/* eslint-disable react-hooks/rules-of-hooks */
import { useApi } from '@api';
import { useQuery } from '@tanstack/react-query';
import { useAuthenticationService } from 'admin-portal-shared-services';

interface ForecastUploadProcessDataResponse {
  status?: string;
  fileUrl?: string;
  inconsistencies?: number;
  regions?: number;
  skus?: number;
}

const FEATURE = 'FORECAST';

export const ForecastUploadProcessData = (processId: string, enable: boolean) => {
  const { get } = useApi();
  const authentication = useAuthenticationService();
  const { user_country: country, language } = authentication.getUserCountryAndLanguage();
  const userId = authentication.getUserId();
  const vendorId = authentication.getVendorId();

  const { refetch, data } = useQuery({
    queryKey: ['processingStatus', processId, enable],

    queryFn: async () => {
      const url = `api/link-collab-forecast-bff/spreadsheet?processId=${processId}`;

      const response = await get({
        url,
        config: {
          headers: {
            country,
            requestTraceId: processId,
            feature: FEATURE,
            language,
            userId,
            vendorId,
          },
        },
      });

      let convertedResponse: ForecastUploadProcessDataResponse = {};

      /* istanbul ignore next */
      if (response?.data?.spreadsheetDetailContent) {
        convertedResponse = {
          status: response?.data?.spreadsheetDetailContent?.spreadsheet?.status,
          fileUrl: response?.data?.spreadsheetDetailContent?.fileUrl,
          inconsistencies: response?.data?.spreadsheetDetailContent?.spreadsheet?.inconsistencies,
          regions: response?.data?.spreadsheetDetailContent?.spreadsheet?.validClients,
          skus: response?.data?.spreadsheetDetailContent?.spreadsheet?.validContracts,
        };
      }

      /* istanbul ignore next */
      if (response?.data?.spreadsheetContent) {
        convertedResponse = {
          status: response?.data?.spreadsheetContent?.status,
          fileUrl: undefined,
          inconsistencies: response?.data?.spreadsheetContent?.inconsistencies,
          regions: response?.data?.spreadsheetContent?.validClients,
          skus: response?.data?.spreadsheetContent?.validContracts,
        };
      }

      return convertedResponse;
    },

    refetchInterval: (data) => {
      /* istanbul ignore next */
      return data?.status !== 'processed' && processId ? 10000 : false;
    },

    refetchOnWindowFocus: false,
    enabled: enable,
  });

  return { refetch, data: data };
};
